<script setup lang="ts">
    import { useI18n } from 'vue-i18n';
    import { useProvisionTypeQuery } from '@/composables/provision-type';

    defineProps<{
        postalcode: string | undefined;
    }>();

    const { t } = useI18n();
    const { isRentalProvisionType } = useProvisionTypeQuery();
</script>

<template>
    <div class="address-incomplete-display">
        <div class="text-base-semibold-line-height-auto">
            {{ t('CHECKOUT.SHIPPING_ADDRESS_DETAIL.COMPLETE_ADDRESS') }}
        </div>

        <div v-if="postalcode != null">
            {{
                t(`CHECKOUT.SHIPPING_ADDRESS_DETAIL.${isRentalProvisionType ? 'POSTAL_CODE_RENTAL' : 'POSTAL_CODE'}`, {
                    postalCode: postalcode,
                })
            }}
        </div>
    </div>
</template>
<style scoped lang="scss">
    @use 'src/styling/main';

    .address-incomplete-display {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: main.$spacing-2;
    }
</style>
