<script setup lang="ts">
    import FormField from '@/components/form-components/FormField.vue';
    import { useForm } from 'vee-validate';
    import { toTypedSchema } from '@vee-validate/zod';
    import { hasValidationError } from '@/util/hasValidationError';
    import type { AddressDto } from '@containex/portal-backend-dto';
    import Button from 'primevue/button';
    import InputText from 'primevue/inputtext';
    import { CheckoutAddress } from '@/checkout/model/checkout-address';
    import { useMarketQuery, useMarketAction } from '@/composables/market';
    import { useLanguage } from '@/composables/useLanguage';
    import { useI18n } from 'vue-i18n';
    import { string } from 'zod';
    import { ref } from 'vue';

    const props = defineProps<{
        initialValues: AddressDto | null;
    }>();
    const emits = defineEmits<{
        close: [];
        submit: [values: CheckoutAddress];
    }>();

    const { t } = useI18n();
    const marketAction = useMarketAction();
    const { market } = useMarketQuery();
    const { countryCodeToLocalName } = useLanguage();

    const schema = CheckoutAddress.extend({
        postalCode: CheckoutAddress.shape.postalCode
            .trim()
            .refine(async (code) => await marketAction.zipCodeExists(code), {
                message: t('ERROR.CHANGE_REGION.DETAIL'),
            }),
        companyName: string().trim().min(1),
        addressLine1: string().trim().min(1),
        city: string().trim().min(1),
        countryCode: string()
            .min(1)
            .default(props.initialValues?.countryCode ?? ''),
    });

    const { handleSubmit, errors, defineField } = useForm({
        validationSchema: toTypedSchema(schema),
        initialValues: {
            addressLine1: props.initialValues?.address1,
            postalCode: props.initialValues?.postalCode,
            city: props.initialValues?.city,
            companyName: props.initialValues?.company,
            countryCode: props.initialValues?.countryCode,
        },
    });

    const [addressLine1] = defineField('addressLine1');
    const [postalCode] = defineField('postalCode');
    const [city] = defineField('city');
    const [companyName] = defineField('companyName');
    const [countryCode] = defineField('countryCode');

    if (market.value == null) {
        throw Error('Market not found');
    }

    countryCode.value = market.value.code;

    const onSubmit = handleSubmit((values) => {
        emits('submit', {
            ...values,
            countryCode: values.countryCode.toLowerCase(),
        });
    });

    const countryName = ref(countryCodeToLocalName(countryCode.value ?? ''));
</script>

<template>
    <form class="flex-container" @submit.prevent="onSubmit">
        <FormField :label="t('FORM.COMPANY_NAME')" :error-message="errors.companyName">
            <InputText
                v-model="companyName"
                class="full-width"
                :invalid="hasValidationError(errors.companyName)"
                data-testid="address-form-company-name"
            />
        </FormField>
        <FormField :label="t('FORM.ADDRESS_LINE_1')" :error-message="errors.addressLine1">
            <InputText
                v-model="addressLine1"
                class="full-width"
                :invalid="hasValidationError(errors.addressLine1)"
                data-testid="address-form-address"
            />
        </FormField>
        <div class="row">
            <FormField class="zip" :label="t('FORM.POSTAL_CODE')" :error-message="errors.postalCode">
                <InputText
                    v-model="postalCode"
                    class="full-width"
                    :invalid="hasValidationError(errors.postalCode)"
                    data-testid="address-form-postalcode"
                />
            </FormField>
            <FormField class="city" :label="t('FORM.CITY')" :error-message="errors.city">
                <InputText
                    v-model="city"
                    class="full-width"
                    :invalid="hasValidationError(errors.city)"
                    data-testid="address-form-city"
                />
            </FormField>
        </div>
        <div>
            <FormField :label="t('FORM.COUNTRY')">
                <InputText :model-value="countryName" disabled class="full-width" />
            </FormField>
        </div>
        <div class="buttons">
            <Button
                type="button"
                class="back-button-border"
                outlined
                :text="true"
                :label="t('COMMON.CANCEL')"
                @click="emits('close')"
            ></Button>
            <Button type="submit" :label="t('FORM.SAVE')" data-testid="address-form-submit-button" />
        </div>
    </form>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .back-button-border {
        border-color: main.$color-primary-500;
    }

    .flex-container {
        display: flex;
        flex-direction: column;
    }

    h4 {
        display: none;
        font-weight: 700;
        line-height: 1.5;
    }

    @include main.for-breakpoint-md {
        h4 {
            display: block;
        }
    }

    .address {
        padding-top: main.$spacing-5;
    }

    .row {
        display: flex;
        flex-direction: column;
        column-gap: main.$spacing-5;

        .flex-grow {
            flex-grow: 1;
        }
    }

    @include main.for-breakpoint-md {
        .row {
            flex-direction: row;

            .zip {
                width: 25%;
            }

            .city {
                width: 75%;
            }
        }
    }

    .buttons {
        display: flex;
        justify-content: end;
        gap: main.$spacing-3;
        padding-top: main.$spacing-5;
        padding-bottom: main.$spacing-5;
    }
</style>
