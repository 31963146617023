<script setup lang="ts">
    import type { LineItem } from '@containex/portal-backend-api-client';
    import { computed } from 'vue';
    import ProductImage from '@/components/ProductImage.vue';
    import { useI18n } from 'vue-i18n';
    import { isAdditionalProduct } from '@/util/line-items';
    import { getComputedProductDetailUrlByProvisionType } from '@/util/router';

    const props = defineProps<{
        lineItem: LineItem;
    }>();

    const { t } = useI18n();

    const product = computed(() => props.lineItem.variant.product);
    const productDetailUrl = getComputedProductDetailUrlByProvisionType();
</script>

<template>
    <div class="line-item-container">
        <div class="line-item-image-container">
            <ProductImage
                :url="lineItem.thumbnail ?? undefined"
                :alt="lineItem.variant.product?.title ?? ''"
                class="product-image"
            />
        </div>
        <div class="line-item-content">
            <div v-if="product != null">
                <div v-if="isAdditionalProduct(product?.article_type ?? '')" class="line-item-title">
                    {{ product.title }}
                </div>
                <router-link
                    v-else-if="productDetailUrl != null"
                    :to="{
                        name: productDetailUrl,
                        params: { id: product.id, variantId: lineItem.variant_id },
                    }"
                    class="line-item-title"
                >
                    {{ product.title }}
                </router-link>
                <div class="line-item-quantity">
                    {{ t('CART.CART_ITEM.QUANTITY_WITH_NUMBER', { quantity: lineItem.quantity }) }}
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
    @use 'src/styling/main' as *;

    .line-item-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    @include for-breakpoint-md {
        .line-item-container {
            flex-wrap: nowrap;
        }
    }

    .line-item-image-container {
        justify-content: space-between;
    }

    .line-item-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: $spacing-3;
    }

    .line-item-title {
        font-size: $font-size-3;
        overflow: hidden;
        text-overflow: ellipsis;
        text-decoration: none;
        word-break: break-all;
        color: $color-text;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        line-height: 1.1;
    }

    .line-item-quantity-price-container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    @include for-breakpoint-md {
        .line-item-quantity-price-container {
            flex-wrap: nowrap;
        }
    }

    .line-item-quantity {
        font-size: $font-size-3;
        color: $color-text-gray;
    }

    .cart-item-action-divider {
        border-right: 1px solid;
        border-color: $color-text-gray;
        height: $font-size-4;
    }

    .product-image {
        width: 48px;
        height: 48px;
        object-fit: contain;
    }
</style>
