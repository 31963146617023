<script setup lang="ts">
    import { computed } from 'vue';
    import type { LineItem, LineItemGroup } from '@containex/portal-backend-api-client';
    import { DeliveryTime, TransportType, TransportTypeCostDto } from '@containex/portal-backend-dto';
    import { usePriceFormatter } from '@/composables/formatPrice';
    import ProductImage from '@/components/ProductImage.vue';
    import IconButtonWithOverlayPanel from '@/components/IconButtonWithOverlayPanel.vue';
    import { differenceInWeeks, parseISO } from 'date-fns';
    import { useI18n } from 'vue-i18n';
    import { useDateFormatter } from '@/composables/date-format';
    import { medusaAddressToAddressDto } from '@containex/portal-medusa-mapper';
    import AddressDisplay from '@/components/AddressDisplay.vue';
    import type { Address } from '@medusajs/medusa/dist/models/address';
    import DetailTable from '@/components/DetailTable.vue';
    import { isProductUsed } from '@/util/isProductUsed';
    import { getProductArticleLineTranslation, getProductDimension } from '@/util/productDetailTranslation';
    import ChippedContent from '@/components/ChippedContent.vue';
    import { isAdditionalProduct } from '@/util/line-items';

    interface ProductDetails {
        name: string;
        value?: string | number | null;
        unit?: string;
    }

    const props = defineProps<{
        lineItem: LineItem;
        lineItemGroup: LineItemGroup;
        transportOption: TransportTypeCostDto;
        deliveryAddress: Address | null;
        isRental: boolean;
    }>();

    const product = computed(() => props.lineItem.variant.product);
    const { formatPrice } = usePriceFormatter();
    const { t } = useI18n();
    const { dateFormatter, relativeDateFormatter } = useDateFormatter();

    const isUsed = computed(() => isProductUsed(product.value));

    const earliestPossibleDeliveryDate = computed(() => {
        const date = props.lineItemGroup.estimated_delivery_date_start;

        return date != null ? parseISO(date) : undefined;
    });

    const earliestPossibleDeliveryDateEnd = computed(() => {
        const date = props.lineItemGroup.estimated_delivery_date_end;

        return date != null ? parseISO(date) : undefined;
    });

    const earliestPossibleDeliveryDateWeekDisplay = computed(() => {
        if (earliestPossibleDeliveryDate.value == null) {
            return t('CART.STEPS.DELIVERY.DELIVERY_DATE_UNKNOWN');
        }

        const difference = differenceInWeeks(earliestPossibleDeliveryDate.value, Date.now());

        return relativeDateFormatter.formatWeeks(difference).value;
    });

    const earliestPossibleDeliveryDateSpan = computed(() => {
        if (earliestPossibleDeliveryDate.value != null && earliestPossibleDeliveryDateEnd.value != null) {
            return `${dateFormatter.formatDate(earliestPossibleDeliveryDate.value)} - ${dateFormatter.formatDate(earliestPossibleDeliveryDateEnd.value)}`;
        }
        return t('CART.STEPS.DELIVERY.DELIVERY_DATE_UNKNOWN');
    });

    function deliveryOptionDisplayFor(transportType: TransportType): string {
        switch (transportType) {
            case TransportType.CraneTruck:
                return t('CART.STEPS.DELIVERY.METHOD.CRANE_TRUCK.TITLE');
            case TransportType.PickUp:
                return t('CART.STEPS.DELIVERY.METHOD.PICK_UP.TITLE');
            case TransportType.Truck:
                return t('CART.STEPS.DELIVERY.METHOD.TRUCK.TITLE');
        }
    }

    const productDetails = computed<ProductDetails[]>(() => [
        {
            name: 'PRODUCT.CONTAINER_AMOUNT',
            value: product.value?.amount_containers,
        },
        {
            name: 'PRODUCT.CONTAINER_TYPE',
            value: product.value?.article_class,
        },
        {
            name: 'PRODUCT.PRODUCT_LINE',
            value: getProductArticleLineTranslation(product.value?.article_line),
        },
        {
            name: 'PRODUCT.LENGTH_FEET',
            value: product.value?.length_feet,
        },
        {
            name: 'PRODUCT.DIMENSION',
            value: getProductDimension(product.value ?? null),
        },
        {
            name: 'PRODUCT.WEIGHT',
            value: product.value?.weight,
            unit: 'kg',
        },
        {
            name: 'PRODUCT.ELECTRICITY',
            value: product.value?.electricity,
        },
        {
            name: 'PRODUCT.COLOR',
            value: product.value?.color,
        },
    ]);

    const filteredProductDetails = computed(() =>
        productDetails.value.filter(
            (detail) =>
                detail.value != null &&
                detail.value !== '' &&
                !(detail.name === 'PRODUCT.CONTAINER_AMOUNT' && typeof detail.value === 'number' && detail.value <= 1)
        )
    );
</script>

<template>
    <div class="checkout-cart-item">
        <div class="checkout-cart-item-info-container">
            <div class="cart-item-image-container">
                <ProductImage
                    v-if="product != null"
                    :url="lineItem.thumbnail ?? undefined"
                    :alt="product.title ?? ''"
                    class="product-image"
                />
            </div>
            <div class="checkout-cart-item-description">
                <div class="product-title">{{ product?.title }}</div>
                <div class="product-quantity">
                    {{ t('CHECKOUT.SUMMARY.QUANTITY', { quantity: lineItem.quantity }) }}
                </div>
            </div>
            <div class="checkout-cart-item-icon-container">
                <IconButtonWithOverlayPanel v-if="transportOption.transportType === TransportType.PickUp">
                    <template #icon>
                        <i class="pi pi-building circled-icon building-icon" />
                    </template>
                    <template #overlay-panel-content>
                        <div class="overlayPanel">
                            <div class="transport-type-header text-base-bold-line-height-auto">
                                {{ deliveryOptionDisplayFor(transportOption.transportType) }}
                            </div>
                            <div class="delivery-address">
                                <div class="text-base-semibold-line-height-auto">
                                    {{
                                        t('CART.STEPS.DELIVERY.SUMMARY.PICK_UP.ADDRESS', {
                                            depot: lineItemGroup.depot.address.city,
                                        })
                                    }}
                                </div>
                                <div class="delivery-address-content">
                                    <AddressDisplay
                                        :address="medusaAddressToAddressDto(props.lineItemGroup.depot.address)"
                                    ></AddressDisplay>
                                </div>
                            </div>
                            <div v-if="!isRental" class="delivery-time">
                                <div class="text-base-semibold-line-height-auto">
                                    {{ t('CART.STEPS.DELIVERY.SUMMARY.PICK_UP.TIME') }}
                                </div>
                                <div class="delivery-time-content">{{ earliestPossibleDeliveryDateSpan }}</div>
                            </div>
                        </div>
                    </template>
                </IconButtonWithOverlayPanel>
                <IconButtonWithOverlayPanel v-else>
                    <template #icon>
                        <i class="pi pi-truck circled-icon truck-icon" />
                    </template>
                    <template #overlay-panel-content>
                        <div class="overlayPanel">
                            <div class="transport-type-header text-base-bold-line-height-auto">
                                {{ deliveryOptionDisplayFor(transportOption.transportType) }}
                            </div>
                            <div class="delivery-address">
                                <div class="text-base-semibold-line-height-auto">
                                    {{ t('CART.STEPS.DELIVERY.SUMMARY.DELIVERY.ADDRESS') }}
                                </div>
                                <div class="delivery-address-content">
                                    <AddressDisplay
                                        v-if="deliveryAddress !== null"
                                        :address="medusaAddressToAddressDto(deliveryAddress)"
                                        :include-company-name="true"
                                    ></AddressDisplay>
                                    <span v-else>
                                        {{ t('CART.STEPS.DELIVERY.SUMMARY.DELIVERY.ADDRESS_INCOMPLETE') }}
                                    </span>
                                </div>
                            </div>
                            <div v-if="!isRental" class="delivery-time">
                                <div class="text-base-semibold-line-height-auto">
                                    {{ t('CART.STEPS.DELIVERY.SUMMARY.DELIVERY.TIME') }}
                                </div>
                                <div class="delivery-time-content">
                                    <template v-if="lineItemGroup.delivery_time == DeliveryTime.Custom">
                                        {{ earliestPossibleDeliveryDateSpan }}
                                    </template>
                                    <template v-else>
                                        <div class="delivery-time-content-estimated-delivery">
                                            {{ t('CART.STEPS.DELIVERY.ESTIMATED_DELIVERY') }}
                                        </div>
                                        <div>{{ earliestPossibleDeliveryDateWeekDisplay }}</div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </template>
                </IconButtonWithOverlayPanel>
                <IconButtonWithOverlayPanel v-if="!isAdditionalProduct(lineItem.variant.product?.article_type ?? '')">
                    <template #icon>
                        <i class="pi pi-info-circle info-icon" />
                    </template>
                    <template #overlay-panel-content>
                        <div class="overlayPanel overlay-panel-product-detail">
                            <ChippedContent :is-used="isUsed" :show-chip="!isRental">
                                <template #content>
                                    <ProductImage
                                        :url="lineItem.thumbnail ?? undefined"
                                        :alt="product?.title ?? ''"
                                        class="overlay-panel-product-image"
                                    />
                                </template>
                            </ChippedContent>
                            <div class="overlay-panel-title text-xl-bold-line-height-auto">
                                {{ product?.title }}
                            </div>
                            <DetailTable
                                class="overlay-panel-product-table"
                                :product-details="filteredProductDetails"
                                :table-header="null"
                            />
                        </div>
                    </template>
                </IconButtonWithOverlayPanel>
            </div>
        </div>
        <div class="price-item">
            <div>{{ t('CHECKOUT.CART.PRICE') }}</div>
            <div>{{ formatPrice(lineItem.subtotal ?? 0) }}</div>
        </div>
    </div>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .checkout-cart-item {
        display: flex;
        flex-direction: column;
        gap: main.$spacing-3;
        height: fit-content;
        background: main.$color-white;
    }

    .info-icon {
        color: main.$color-primary-500;
        font-size: 18px;
    }

    .truck-icon {
        font-size: 10px;
    }

    .circled-icon {
        color: main.$color-order-successful;
        border: 1px solid var(--p-color-order-successful);
        border-radius: 50%;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .building-icon {
        font-size: 12px;
    }

    .overlayPanel {
        color: main.$color-text;
    }

    .transport-type-header {
        padding-bottom: main.$spacing-3;
    }

    .delivery-address {
        padding-bottom: main.$spacing-4;
    }

    .delivery-time-content {
        color: main.$color-green-dark;
    }

    .delivery-time-content-estimated-delivery {
        color: main.$color-text;
        padding-bottom: 0;
    }

    .checkout-cart-item-info-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: main.$spacing-4;
    }

    .checkout-cart-item-description {
        width: 100%;
    }

    .checkout-cart-item-icon-container {
        display: flex;
        align-items: center;
        gap: main.$spacing-3;
    }

    .checkout-cart-item-icon-container div:hover {
        border: 0 none;
        border-radius: 50%;
        background-color: main.$color-background-bluegray;
    }

    .price-item {
        display: flex;
        justify-content: space-between;
        line-height: 1;
        font-size: main.$font-size-2;
    }

    .product-title {
        font-size: main.$font-size-2;
    }

    .product-quantity {
        font-size: main.$font-size-2;
        color: main.$color-secondary;
    }

    .product-image {
        width: 48px;
        height: 48px;
    }

    .cart-item-image-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .overlay-panel-product-detail {
        max-width: 416px;
    }

    .overlay-panel-product-image {
        max-width: 100%;
        min-height: 200px;
    }

    .overlay-panel-title {
        margin-bottom: main.$spacing-3;
    }

    .overlay-panel-product-table {
        font-size: main.$font-size-2;

        :deep(tr) {
            padding-left: 0;
        }
    }
</style>
