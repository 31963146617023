<script setup lang="ts">
    import { ShippingAddressOption } from '@/types/ShippingAddressOption';
    import AddressForm from './AddressForm.vue';
    import { computed, ref } from 'vue';
    import AddressDisplay from '@/components/AddressDisplay.vue';
    import type { AddressDto } from '@containex/portal-backend-dto';
    import Button from 'primevue/button';
    import Dialog from 'primevue/dialog';
    import type { CheckoutAddress } from '@/checkout/model/checkout-address';
    import { breakpoints } from '@/util/breakpoints';
    import RadioCard from '@/components/form-components/RadioCard.vue';
    import CheckoutSectionCard from '@/checkout/components/common/CheckoutSectionCard.vue';
    import BlockUI from 'primevue/blockui';
    import { useI18n } from 'vue-i18n';
    import AddressIncompleteDisplay from '@/checkout/components/common/AddressIncompleteDisplay.vue';
    import { useMarketQuery } from '@/composables/market';
    import { isEnteredAddressEmpty } from '@/util/addressHelpers';

    const props = defineProps<{
        defaultShippingAddress: AddressDto;
        differentShippingAddress: AddressDto;
        shippingAddressOption: ShippingAddressOption;
        blocked: boolean;
        isRental: boolean;
    }>();

    const emits = defineEmits<{
        'select-default-address': [];
        'select-different-address': [newValue: AddressDto];
    }>();

    const { t } = useI18n();
    const isMobile = breakpoints.smaller('md');
    const isCustomAddressDialogVisible = ref(false);
    const { market } = useMarketQuery();

    const defaultShippingAddressDisabled = computed(
        () => props?.defaultShippingAddress?.countryCode?.toLowerCase() !== market?.value?.code?.toLowerCase()
    );
    const defaultShippingAddressInfoMessage = computed(() =>
        defaultShippingAddressDisabled.value
            ? t('CHECKOUT.SHIPPING_ADDRESS_DETAIL.COMPANY_ADDRESS_DIFFERENT_COUNTRY')
            : undefined
    );

    function handleCloseCustomAddressDialog(): void {
        isCustomAddressDialogVisible.value = false;
    }

    function updateShippingAddressToDefaultOption(): void {
        emits('select-default-address');
    }

    function updateShippingAddressToDifferentOption(): void {
        emits('select-different-address', props.differentShippingAddress);
    }

    function handleAddressSubmit(values: CheckoutAddress): void {
        if (market.value == null) {
            throw Error('Market not found');
        }
        emits('select-different-address', {
            postalCode: values.postalCode,
            company: values.companyName,
            address1: values.addressLine1,
            city: values.city,
            countryCode: values.countryCode,
        });
        handleCloseCustomAddressDialog();
    }
</script>

<template>
    <div class="container">
        <CheckoutSectionCard>
            <template #header>
                <span v-if="!isRental">{{ t('CART.STEPS.DELIVERY_ADDRESS') }}</span>
                <div v-else class="text-base-bold-line-height-auto rental-header">
                    <i class="pi pi-truck" />
                    <div>
                        {{ t('CART.STEPS.SELECT_DELIVERY_ADDRESS') }}
                    </div>
                </div>
            </template>
            <template #content>
                <span v-if="!isRental">{{ t('CHECKOUT.SHIPPING_ADDRESS_DETAIL.DESCRIPTION') }}</span>
                <div>
                    <BlockUI :blocked="blocked">
                        <RadioCard
                            :model-value="shippingAddressOption"
                            class="address-selection different-shipping"
                            name="shippingAddressOption"
                            :value="ShippingAddressOption.CUSTOM"
                            @update:model-value="updateShippingAddressToDifferentOption"
                        >
                            <template #header>
                                <div class="address-selection-container">
                                    <template v-if="!isEnteredAddressEmpty(differentShippingAddress)">
                                        <AddressDisplay
                                            :address="differentShippingAddress"
                                            :include-company-name="true"
                                        />
                                        <div class="address-btn-container">
                                            <Button
                                                text
                                                class="edit-btn text-base-semibold-line-height-auto"
                                                outlined
                                                :label="isMobile || isRental ? '' : t('FORM.EDIT')"
                                                icon="pi pi-pencil"
                                                @click.stop="isCustomAddressDialogVisible = true"
                                            />
                                        </div>
                                    </template>
                                    <template v-else>
                                        <AddressIncompleteDisplay :postalcode="differentShippingAddress.postalCode" />
                                        <div class="address-btn-container">
                                            <Button
                                                class="complete-btn text-base-semibold-line-height-auto"
                                                :label="isMobile || isRental ? '' : t('FORM.COMPLETE')"
                                                icon="pi pi-pencil"
                                                data-testid="shipping-address-selection-incomplete-button"
                                                @click.stop="isCustomAddressDialogVisible = true"
                                            />
                                        </div>
                                    </template>
                                </div>
                            </template>
                        </RadioCard>
                        <RadioCard
                            :model-value="shippingAddressOption"
                            class="address-selection"
                            name="shippingAddressOption"
                            :value="ShippingAddressOption.DEFAULT"
                            :disabled="defaultShippingAddressDisabled"
                            :info-message="defaultShippingAddressInfoMessage"
                            @update:model-value="updateShippingAddressToDefaultOption"
                        >
                            <template #header>
                                <AddressDisplay
                                    v-if="defaultShippingAddress != null"
                                    :address="defaultShippingAddress"
                                    :include-company-name="true"
                                />
                            </template>
                        </RadioCard>
                    </BlockUI>
                </div>
            </template>
        </CheckoutSectionCard>

        <Dialog
            :visible="isCustomAddressDialogVisible"
            modal
            :style="{ width: '45rem' }"
            @update:visible="handleCloseCustomAddressDialog"
        >
            <template #header>
                <div class="dialog-header-typography">
                    {{ t('CHECKOUT.SHIPPING_ADDRESS_DETAIL.DIFFERENT_SHIPPING_ADDRESS') }}
                </div>
            </template>
            <AddressForm
                :initial-values="differentShippingAddress"
                @close="handleCloseCustomAddressDialog"
                @submit="handleAddressSubmit"
            />
        </Dialog>
    </div>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .rental-header {
        display: flex;
        gap: main.$spacing-3;
        align-items: center;
    }

    .container {
        display: flex;
        flex-direction: column;
        row-gap: main.$spacing-5;
    }

    .address-selection {
        &:first-child {
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
        }

        &:last-child {
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
        }
    }

    .address-selection-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .add-address-btn {
        gap: main.$spacing-5;
        padding: main.$spacing-4 main.$spacing-5;
        text-align: left;
        font-weight: 500;
    }

    @include main.for-breakpoint-md {
        .add-address-btn {
            max-width: fit-content;
        }
    }

    .edit-btn {
        border: 1px solid main.$color-primary-500;
    }

    .complete-btn {
        margin-left: auto;
    }

    .address-btn-container {
        min-width: 41px;
        max-height: 41px;
        padding-left: main.$spacing-3;
    }
</style>
