<script setup lang="ts"></script>

<template>
    <div class="checkout-section-card">
        <h3 class="text-xl-bold-line-height-auto delivery-title">
            <slot name="header"></slot>
        </h3>
        <slot name="content"></slot>
    </div>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .checkout-section-card {
        display: flex;
        flex-direction: column;
        gap: main.$spacing-4;
        background: main.$color-white;
        padding: main.$spacing-5;
        border-radius: 4px;
    }

    .delivery-title {
        padding: main.$spacing-3 0;
    }
</style>
