import { object, string, z } from 'zod';

export const CheckoutAddress = object({
    postalCode: string(),
    countryCode: string(),
    city: string(),
    companyName: string(),
    addressLine1: string(),
});

export type CheckoutAddress = z.infer<typeof CheckoutAddress>;
